<template>
  <!-- 合同文件管理列表 -->
  <div class="contract-file-manage-list">
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="resetFrom"
      ref="formData"
    >
      <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        />
      </div>
    </base-form>
    <!-- 新增弹窗 -->
   <div class="addBtn">
      <base-button label="新增" @click="addVisible = true" icon="el-icon-plus" />
    </div>
    <!-- 数据表格 -->
    <base-table
      class="main-page-table with-add"
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
        stripe: true,
      }"
      :loadCount="loadCount"
      :api="api"
      :getApi="'getPage'"
      ref="tableData"
      :webPage="false"
      :dataSource.sync="tableData"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <!-- <icon-button
          @click="cancel(scope.row)"
          content="撤回"
          icon="iconfont iconchehui"
          v-if="showCancelBtn(scope.row)"
          :disabled="disabled"
        /> -->
        <!-- <icon-button
          @click="back(scope.row)"
          content="退回"
          icon="iconfont icona-tuihuipiliangtuihui"
          :disabled="disabled"
            v-if="showBackbtn(scope.row)"
        /> -->
          <icon-button
          @click="againSeal(scope.row)"
          content="补签重签"
          icon="iconfont iconshuaxin"
          :disabled="disabled"
            v-if="showAgainSealBtn(scope.row)"
        />
         <!-- 按钮组 -->
        <icon-button
          @click="edit(scope.row)"
          content="编辑"
          icon="iconfont iconbianji3"
          v-if="buttonShowEdit(scope.row)"
          :disabled="disabled"
        />
          <icon-button
          content="查看"
          icon="iconfont iconchakan"
          @click="info(scope.row)"
          v-else
          :disabled="disabled"
        />
          <icon-button
          @click="delSeal(scope.row)"
          content="删除"
          icon="iconfont iconshanchu1"
            v-if="isShowDelSealBtn(scope.row)"
        />
      </template>
    </base-table>
     <back-dialog :visible.sync="visible" :queryTaskResultDTO='queryTaskResultDTO' :taskId='taskId' @handleFilter='handleFilter' />
     <!-- 新增弹窗  -->
      <select-dialog :visible.sync="addVisible"></select-dialog>
  </div>
</template>
<script>
import BackDialog from '@/pages/business/components/back-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import baseForm from '@/components/common/base-form/base-form.vue'
import { dataForm, dataTable } from './utils/config.js'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { progressApi, replenishApi } from '@/api/businessApi'
import { supplierApi } from '@/api/companyApi'
import Storage from '@/utils/storage'
import { getDictLists } from '@/filters/fromDict'
import SelectDialog from './components/select-dialog.vue'

export default {
  name: 'contractFileManage',
  components: { baseForm, BaseTable, IconButton, BaseButton, BackDialog, SelectDialog },
  data () {
    return {
      fold: false,
      loadCount: 0,
      addVisible: false, // 新增弹窗
      taskId: '',
      queryParas: {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1,
        processStatus: 'CONTRACT'
      },
      visible: false,
      queryTaskResultDTO: {},
      disabled: false,
      tableData: [],
      gysData: []
    }
  },
  computed: {
    api () {
      return progressApi
    },
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    // 签约状态
    signStatusOptions () {
      return getDictLists('SIGN_TASK_STATUS')
    },
    // 审核状态
    reviewOptions () {
      return getDictLists('REVIEW_STATUS')
    }
  },
  mounted () {
    this.handleFilter()
  },
  activated () {
    this.handleFilter()
  },
  methods: {
    // 是否显示删除按钮
    isShowDelSealBtn (data) {
      const reviewStatusPassArr = [0, 50, 40]
      return (data.createType === '1' && reviewStatusPassArr.some(item => item === data.reviewStatus))
      // const applyUserId = data?.otherActions ? JSON.parse(data.otherActions) : {}
      // if (flag) {
      //   if (applyUserId?.applyUserId === this.$store.state.user.userInfo.userId) {
      //     return true
      //   } else {
      //     return false
      //   }
      // }
    },
    // 签署时间
    changeSignDate (data) {
      console.log(data, 'data')
      if (data) {
        this.$set(this.queryParas, 'signDateStart', data[0])
        this.$set(this.queryParas, 'signDateEnd', data[1])
      } else {
        this.$set(this.queryParas, 'signDateStart', '')
        this.$set(this.queryParas, 'signDateEnd', '')
      }
    },

    // 补签重盖
    againSeal (row) {
      this.$stateConfirm({
        title: '提示',
        message: '此操作会新建单号进行补签，是否继续？',
        show: true,
        type: 'warning'
      })
        .then(() => {
          const data = {
            businessNo: row.businessNo,
            capitalSideId: row.capitalSideId,
            capitalSideProductId: row.capitalSideProductId,
            gysId: row.gysId
          }
          replenishApi.saveReview(data).then(res => {
            if (res.data && res.code === '0') {
              this.$router.push({
                path: '/business/contractFileMake/contractFileMakeSignature',
                query: { businessId: row.keyId }
              })
              this.$set(row, 'batchNum', res.data)
              this.$set(row, 'reviewResult', false)
              Storage.setLocal('contractInfo', row)
            } else {
              this.warning('创建补签任务失败')
            }
          })
        })
        .catch(() => {})
    },
    // 退回
    back (row) {
      if (row.queryTaskResultDTO && row.queryTaskResultDTO.taskId) {
        const params = {
          businessId: row.queryTaskResultDTO.bizId,
          instanceId: row.queryTaskResultDTO.instanceId,
          taskId: row.queryTaskResultDTO.taskId,
          processName: row.processStatusId
        }
        progressApi.updatestatus(params).then(res => {
          if (res.success) {
            this.queryTaskResultDTO = row.queryTaskResultDTO
            this.queryTaskResultDTO.processStatus = this.queryParas.processStatus
            this.taskId = row.queryTaskResultDTO.taskId
            this.visible = true
          }
        })
      } else {
        this.warning('数据错误')
      }
    },
    // 删除
    delSeal (row) {
      this.$stateConfirm({
        title: '提示',
        message: '确认删除吗？',
        show: true,
        type: 'warning'
      }).then(() => {
        const data = { businessId: row.keyId, batchNum: row.batchNum }
        replenishApi.deleteSupplementDocumentTask(data).then(res => {
          if (res.data) {
            this.success('删除成功')
            this.handleFilter()
          } else {
            this.warning('删除失败')
          }
        })
      }).catch(() => {

      })
    },
    // 撤回按钮
    showCancelBtn (row) {
      const flag = row.reviewStatus === 10
      const applyUserId = row?.otherActions ? JSON.parse(row.otherActions) : {}
      if (flag) {
        if (applyUserId?.applyUserId === this.$store.state.user.userInfo.userId) {
          return true
        } else {
          return false
        }
      }
    },
    // 补签重签
    showAgainSealBtn (row) {
      // const flag = row.status === 2
      // if (flag) {
      //   if (this.$store.state.user.userInfo.userId === row.operator || !row?.operator) {
      //     return true
      //   } else {
      //     return false
      //   }
      // }
      return row.status === 2 || row.status === 3
    },
    //  编辑按钮是否显示
    buttonShowEdit (row, type) {
      // operator 为空或不存在，首次进入页面
      const list = [0, 40, 50, 60]
      let flag = false
      flag = list.some((val) => {
        return row.reviewStatus === val
      })
      if (flag) {
        if (this.$store.state.user.userInfo.userId === row.operator || !row?.operator) {
          return true
        } else {
          return false
        }
      }
    },
    // 退回按钮
    showBackbtn (row) {
      const flag = row.fileMakeStatus === 30 && row.reviewStatus === 40 && row.createType === '0' && row.finaaceProductName !== '稳保付'
      const applyUserId = row?.otherActions ? JSON.parse(row.otherActions) : {}
      if (flag) {
        if (applyUserId?.applyUserId === this.$store.state.user.userInfo.userId) {
          return true
        } else {
          return false
        }
      }
    },
    // 按钮是否显示
    // buttonShowInfo (row) {
    //   const list = [10, 20, 30, 90]
    //   let flag = false
    //   flag = list.some((val) => {
    //     return row.reviewStatus === val
    //   })
    //   if (flag) {
    //     return row.fileMakeStatus !== 10
    //   }
    // },
    // 查看详情
    info (row) {
      this.$router.push({ path: '/business/contractFileManage/info' })
      Storage.setSession('examine', row)
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    /**
     * 查询
     */
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    /**
     * 重置表单
     */
    resetFrom () {
      this.queryParas = {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1,
        processStatus: 'CONTRACT'
      }
    },
    // 编辑
    edit (data) {
      // 调用接口查询是否有补签任务
      replenishApi.queryDocumentEditStatus({ businessId: data.keyId, instanceId: data.instanceId }).then(res => {
        if (res.data && res.code === '0') {
          // 加入判断,进入正常任务还是自定义签章
          if (data.createType === '1') {
            this.$router.push({
              path: '/business/contractFileMake/contractFileMakeSignature',
              query: { businessId: data.keyId }
            })
          } else {
            this.$router.push({
              path: '/business/contractFileMake/contractFileMake',
              query: { businessId: data.keyId }
            })
          }
        }
      })

      Storage.setLocal('contractInfo', data)
    },
    // 撤回
    cancel (row) {
      this.disabled = true
      const data = {
        businessId: row.keyId,
        processStatus: row.processStatusId,
        batchNum: row.batchNum || null
      }
      this.$stateConfirm({
        title: '提示',
        message: '是否撤回？',
        show: true,
        type: 'warning'
      })
        .then(() => {
          this.api.cancel(data).then((res) => {
            this.success('撤回成功')
            this.handleFilter()
            this.disabled = false
          })
        })
        .catch(() => {
          this.disabled = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.contract-file-manage-list {
  height: 100%;
}
.addBtn{
  margin-top: 10px;
  padding-top: 20px;
  padding: 20px 20px 0px 18px;
  background-color: #fff;
  text-align: right;
}

</style>
